.signin-div {
  box-shadow: 0 0 10px #d2d2d2;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  width: 500px;
}

.display-login-reject {
  display: flex;
  align-items: center;
  font-size: 25px;
  color: red;
}
