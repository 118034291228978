.signup-div {
  box-shadow: 0 0 10px #d2d2d2;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  width: 500px;
}

.form-div {
  display: flex;
  flex-direction: column;
}

.form-div input {
  margin-bottom: 16px;
}

.signup-label {
  position: relative;
}

.leave-signup {
  font-size: 25px;
}
